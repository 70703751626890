import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import moment from 'moment'
import { Link } from 'react-router-dom'

const AboutSection = (props) => {

    const [companyDetails] = useContext(CompanyContext);
    const [aboutSectionData, setAboutSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const aboutSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-about-section-data/' + company_id + '/' + template_id
                    );
                    const result = await aboutSection.json();

                    if (result && result.hotel_about_details) {

                        for (let q = 0; q < result.hotel_about_details.length; q++) {
                            for (let r = 0; r < result.all_hotels_data.length; r++) {
                                if (result.hotel_about_details[q].hotel_id === result.all_hotels_data[r].hotel_id) {
                                    result.hotel_about_details[q].hotel_name = result.all_hotels_data[r].hotel_name
                                }
                            }
                        }
                    }

                    setAboutSectionData({ "hotel_about_details": result && result.hotel_about_details, "section_details": result && result.section_details,"all_hotels_data": result && result.all_hotels_data });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = aboutSectionData && aboutSectionData.section_details

    let display_section_title_des = props && props.data

    let total_property = aboutSectionData && aboutSectionData.all_hotels_data && aboutSectionData.all_hotels_data.length
    var fromdate = moment();
    var todate = moment(fromdate).add(1, 'days')
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    let iframe
    let encryptData
    return (
        <div>
            <div className="container">
                {section_details && !display_section_title_des &&
                    <div>
                        <div className="section-header text-center text-uppercase">
                            <h1>{section_details.section_title}</h1>
                        </div>
                        <div className="center_title">
                            <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                        </div>
                    </div>}
            </div>

            {aboutSectionData && aboutSectionData.hotel_about_details && aboutSectionData.hotel_about_details.length > 0 && <section className={section_details && section_details.background_image && section_details.background_image ? "about-us-bg parallax-bg": "about-us-bg"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>

                <OwlCarousel
                    className="owl-theme"
                    items={1}
                    loop
                    nav
                >
                    {aboutSectionData.hotel_about_details.map(function (item, id) {                        
                        let send_hotel_name = item.hotel_name.replace(/[^a-zA-Z ]/g, "");
                        send_hotel_name = send_hotel_name.replace(/ +/g, "_");
                        send_hotel_name = send_hotel_name.replace(",", "");
                        send_hotel_name = send_hotel_name.toLowerCase();

                        let be_data = to_date + "|" + from_date + '|' + item.hotel_id + '|' + "" + '|' + "" + '|' + ""
                        encryptData = btoa(be_data);
                        // iframe = encryptData

                        return (
                            <div className="item" key={id}>
                                <div className="fluid-know-area">
                                    <div className="work-image-ser"><img src={config.wbImagesEndpoint + '/' + item.image_name} alt={item.about_image_alt} className="img-responsive" /></div>

                                    <div className="service-promo">
                                        <div className="promo-content">
                                            <div className="know-top">
                                                <div className="about-hotel-text">{item.title}</div>
                                                <h3>{item.subtitle}</h3>

                                                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            </div>
                                            <div className="know-bot">
                                                <ul>
                                                    <li>
                                                        <div className="about-cont">
                                                            <div className="text-center">
                                                                {/* <Link className="hotel_book_now_btn" to={{
                                                                    pathname: config.PATH + 'booknow',
                                                                    search: '?' + (iframe),
                                                                }}
                                                                >Book Room
                                                </Link> */}

                                                                <a className="hotel_book_now_btn" href={"https://www.asiatech.in/booking_engine/index3.php?token=NDM5Nw=="}>Book Now</a>

                                                            </div>
                                                        </div>
                                                    </li>
                                                    {total_property > 1 && <li>

                                                        <div className="about-cont">
                                                            <div className="text-center">
                                                                <Link className="hotel_book_now_btn" to={{
                                                                    pathname: config.PATH + '' + send_hotel_name,
                                                                    commonpage: item.hotel_id,
                                                                }} style={{ fontFamily: websitefont }}
                                                                >View Details
                                                </Link>

                                                            </div>
                                                        </div>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </OwlCarousel>
            </section>}
        </div>



    )
}

export default AboutSection;